<template>
  <div class="add-payment-time">
    <el-row>
      <el-col :span="12">
        <div>
          <easy-card class="box box-left" title="添加">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
                label-position="left"
            >
              <el-form-item label="预支付时长(分钟)" prop="prePayTime">
                <el-input
                    v-model="form.prePayTime"
                    class="input-width"
                    placeholder="请输入预支付时长"
                >
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                    size="small"
                    type="primary"
                    @click="submitForm('form')"
                >确定
                </el-button>
                <el-button size="small" @click="resetForm('form')"
                >重置
                </el-button
                >
              </el-form-item>
            </el-form>
          </easy-card>
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <easy-card class="box" title="已添加">
            <el-table
                :data="paymentTimeTable"
                border
                class="common-table"
                height="400px"
                size="medium"
                style="width: 100%"
            >
              <el-table-column label="预支付时长(分钟)" prop="prePayTime">
                <template slot-scope="scope">
                  <span>{{ scope.row.prePayTime }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" prop="contactPerson" width="100">
                <template slot-scope="scope"
                >
                  <el-link type="primary" style="color: #EA5504!important;" @click="deleteRule(scope.row)"
                  >删除
                  </el-link
                  >
                </template
                >
              </el-table-column>
            </el-table>
          </easy-card>
        </div>
      </el-col>
    </el-row>
    <div class="save-button">
      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </div>
  </div>
</template>

<script>
import {getCalcDetailsByCalcTypeId, saveCalcPrepay} from "@/api/price";

export default {
  name: "add-price-payment-time",
  props: {
    id: String
  },
  data() {
    return {
      form: {
        prePayTime: "", //预支付时长
      },
      rules: {
        prePayTime: [
          {required: true, message: "请正确输入预支付时长",}
        ]
      },
      paymentTimeTable: [],
    }
  },
  methods: {
    //添加
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          const list = {
            prePayTime: this.form.prePayTime,
          }
          this.paymentTimeTable = [];
          this.paymentTimeTable.push(list);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置
    resetForm(form) {
      this.$refs[form].resetFields();
    },
    //删除表格数据
    deleteRule(e) {
      const ruleList = this.paymentTimeTable;
      const id = e;
      ruleList.splice(ruleList.indexOf(id), 1);
    },
    //保存表单
    save() {
      const saveList = this.paymentTimeTable[0].prePayTime;
      const body = {
        objectId: this.$route.query.id,
        prePayTime: saveList
      }
      saveCalcPrepay(body).then(res => {
        if (res.code === 30 && res.result) {
          this.$message.success("保存成功");
        }
      })
    },
    //请求详情接口
    async getPaymentTimeList() {
      const res = await getCalcDetailsByCalcTypeId(this.$route.query.id);
      if (res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        if (Object.prototype.hasOwnProperty.call(returnObject, 'calcPrepayResultVo')) {
          if(returnObject.calcPrepayResultVo.prePayTime){
            const list = {
              prePayTime: returnObject.calcPrepayResultVo.prePayTime.toString(),
            };
            this.paymentTimeTable.push(list);
          }
        }
      }
    }
  },
  mounted() {
    this.getPaymentTimeList();
  }
};
</script>

<style lang="less" scoped>
.box {
  padding: 10px 10px;
  //border: 1px solid #ebeef5;
}

.box-left {
  margin-right: 10px;
}
</style>
