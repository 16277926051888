<template>
  <div class="add-price-rule">
    <el-row>
      <el-col :span="12">
        <div>
          <easy-card class="box box-left" title="添加">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
                label-position="left"
            >
              <el-form-item label="选择车牌" prop="plateType">
                <el-select
                    v-model="form.plateType"
                    multiple
                    @change="changeValue"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :disabled="item.disabled"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="生效周期" prop="activeTimeType">
                <el-select v-model="form.activeTimeType">
                  <el-option label="不限" value="0"></el-option>
                  <el-option label="工作日" value="1"></el-option>
                  <el-option label="休息日" value="2"></el-option>
                </el-select>
                <el-checkbox
                    v-model="form.activeLegalHolidays"
                    class="checkbox"
                    false-label="1"
                    label="启用法定节假日"
                    prop="checked"
                    true-label="0"
                ></el-checkbox>
              </el-form-item>

              <el-form-item prop="effectualTime">
                <el-select v-model="form.effectualTime" @change="changeDisable">
                  <el-option label="全天" value="0"></el-option>
                  <el-option label="分时段" value="1"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item v-if="showTimeInput === true" required>
                <el-time-picker
                    v-model="form.activeTimeStart"
                    format="HH:mm"
                    placeholder="开始时间"
                    value-format="HH:mm"
                >
                </el-time-picker>
                <span> ~ </span>
                <el-time-picker
                    v-model="form.activeTimeEnd"
                    format="HH:mm"
                    placeholder="结束时间"
                    value-format="HH:mm"
                >
                </el-time-picker>
              </el-form-item>

              <el-form-item label="计费方式" prop="ruleType">
                <el-radio-group v-model="form.ruleType">
                  <el-radio label="0">按时</el-radio>
                  <el-radio label="1">按次</el-radio>
                  <el-radio label="2">按阶梯</el-radio>
                </el-radio-group>
              </el-form-item>

              <div v-if="form.ruleType === '0'">
                <el-form-item label="单位价格(元)" prop="price">
                  <el-input v-model="form.price" placeholder="请输入单位价格">
                  </el-input>
                </el-form-item>
                <el-form-item label="单位时长(分钟)" prop="calcUnit">
                  <el-input
                      v-model="form.calcUnit"
                      placeholder="请输入单位时长"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item label="不足单位时长" prop="notEnoughUnit">
                  <el-radio v-model="form.notEnoughUnit" label="0"
                  >收费
                  </el-radio
                  >
                  <el-radio v-model="form.notEnoughUnit" label="1"
                  >免费
                  </el-radio
                  >
                  <el-tooltip effect="light" placement="top">
                    <div slot="content">
                      ①不支持与按次、按阶梯计费规则组合。<br/>②不支持封顶规则、特殊免费、特殊封顶。
                    </div>
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                </el-form-item>

                <el-form-item label="切换计费时段" prop="unitChange">
                  <el-radio v-model="form.unitChange" label="0"
                  >开始新单位时长
                  </el-radio
                  >
                  <el-radio v-model="form.unitChange" label="1"
                  >继续当前单位时长
                  </el-radio
                  >
                  <el-tooltip effect="light" placement="top">
                    <div slot="content">
                      ①不支持与按次、按阶梯计费规则组合。<br/>②不支持封顶规则、特殊免费、特殊封顶。
                    </div>
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="免费时长" prop="freeTime">
                  <el-input
                      v-model="form.freeTime"
                      placeholder="请输入免费时长(选填)"
                  >
                    <template slot="append">分钟</template>
                  </el-input>
                </el-form-item>
              </div>

              <div v-if="this.form.ruleType === '1'">
                <el-form-item label="单次收费(元)" prop="price">
                  <el-input v-model="form.price" placeholder="请输入单次收费">
                  </el-input>
                </el-form-item>

                <el-form-item label="免费时长" prop="freeTime">
                  <el-input
                      v-model="form.freeTime"
                      placeholder="请输入免费时长(选填)"
                  >
                    <template slot="append">分钟</template>
                  </el-input>
                </el-form-item>
              </div>

              <div v-if="this.form.ruleType === '2'">
                <el-form-item label="单位时长" prop="calcUnit">
                  <el-row class="tableTitle">
                    <el-col :span="22">
                      <div class="input-margin">
                        <el-input
                            v-model="form.calcUnit"
                            size="small"
                        >
                          <template slot="append">分钟</template>
                        </el-input>
                      </div>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="设置阶梯时段">
                </el-form-item>
                <el-form-item>
                  <el-row class="tableTitle">
                    <el-col :span="15">
                      <div>时间范围(分钟)</div>
                    </el-col>
                    <el-col :span="7">
                      <div>收费金额(元)</div>
                    </el-col>
                    <!--                    <el-col :span="6">-->
                    <!--                      <div>单位时长(分钟)</div>-->
                    <!--                    </el-col>-->
                  </el-row>
                  <el-row
                      v-for="item in form.calcDetailList"
                      :key="item.id"
                      class="tableContent"
                  >
                    <span class="ladderName">阶梯{{ item.calcNo }}:</span>
                    <el-col :span="7">
                      <div class="input-margin">
                        <el-input
                            v-model="item.calcStart"
                            disabled
                            size="small"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="1">
                      <div>~</div>
                    </el-col>
                    <el-col :span="7">
                      <div class="input-margin">
                        <el-input
                            v-model="item.calcEnd"
                            size="small"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="7">
                      <div class="input-margin">
                        <el-input v-model="item.price" size="small"></el-input>
                      </div>
                    </el-col>
                    <!--                    <el-col :span="6">-->
                    <!--                      <div class="input-margin">-->
                    <!--                        <el-input-->
                    <!--                            v-model="item.calcUnit"-->
                    <!--                            size="small"-->
                    <!--                        ></el-input>-->
                    <!--                      </div>-->
                    <!--                    </el-col>-->
                  </el-row>
                  <el-row class="tableTitle">
                    <el-col :span="22">
                      <div class="table-btn">
                        <el-button
                            circle
                            icon="el-icon-plus"
                            size="small"
                            type="primary"
                            @click="addLadder"
                        ></el-button>
                        <el-button
                            circle
                            icon="el-icon-minus"
                            size="small"
                            @click="deleteLadder"
                        ></el-button>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class="tableTitle">
                    <span class="ladderName">剩余阶梯:</span>
                    <el-col :span="11">
                      <div class="input-margin">
                        <el-input
                            v-model="form.surplusLadderPrice"
                            size="small"
                        >
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </el-col>
                    <el-col :span="11">
                      <div class="input-margin">
                        <el-input v-model="form.surplusLadderTime" size="small">
                          <template slot="append">分钟</template>
                        </el-input>
                      </div>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="免费时长" prop="freeTime">
                  <el-row class="tableTitle">
                    <el-col :span="22">
                      <div class="input-margin">
                        <el-input
                            v-model="form.freeTime"
                            placeholder="请输入免费时长(选填)"
                        >
                          <template slot="append">分钟</template>
                        </el-input>
                      </div>
                    </el-col>
                  </el-row>

                </el-form-item>
              </div>

              <el-form-item>
                <el-button
                    size="small"
                    type="primary"
                    @click="submitForm('form')"
                >确定
                </el-button>
                <el-button size="small" @click="resetForm('form')"
                >重置
                </el-button
                >
              </el-form-item>
            </el-form>
          </easy-card>
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <easy-card class="box" title="已添加">
            <el-table
                :data="priceRuleTable"
                border
                class="common-table"
                height="400px"
                size="medium"
                style="width: 100%"
            >
              <el-table-column label="生效车牌" prop="plateType">
                <template slot-scope="scope">
                  <span>{{ formatNumplate(scope.row.plateType) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="生效周期" prop="activeTimeType">
                <template slot-scope="scope">
                  {{
                    scope.row.activeTimeType === "0"
                        ? "不限"
                        : scope.row.activeTimeType === "1"
                            ? "工作日"
                            : "休息日"
                  }}
                  <span v-if="scope.row.activeLegalHolidays === '0'">(启用法定节假日)</span>
                </template>
              </el-table-column>
              <el-table-column label="时间段" prop="effectualTime">
                <template slot-scope="scope">
                  {{ scope.row.effectualTime === "0" ? "全天" : "" }}
                  <span v-if="scope.row.effectualTime === '1'"
                  >{{ scope.row.activeTimeStart }} ~
                    {{ scope.row.activeTimeEnd }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                  label="计费方式"
                  prop="contactNumber"
                  width="250"
              >
                <template slot-scope="scope">
                <span v-if="scope.row.ruleType === '0'">
                  {{ scope.row.price }}元/{{ scope.row.calcUnit }}分钟/不足单位时长{{ scope.row.notEnoughUnit === '0' ? '收费' : '免费' }}/切换计费时段{{ scope.row.unitChange === '0' ? '开始新单位时长' : '继续当前单位时长' }}
                  <span v-if="scope.row.freeTime !== ''">,免费时长{{ scope.row.freeTime }}分钟</span>
                </span>
                  <span v-if="scope.row.ruleType === '1'">
                  {{ scope.row.price }}元/次
                    <span v-if="scope.row.freeTime !== ''">,免费时长{{ scope.row.freeTime }}分钟</span>
                </span>
                  <span v-if="scope.row.ruleType === '2'">
                    <div>单位时长: {{ scope.row.calcUnit }}分钟</div>
                  {{ formatLadderList(scope.row.calcDetailList) }}
                  <div v-for="item in scope.row.calcDetailList"
                       :key="item.text">
                    {{ item.text }}
                  </div>
                  <div>剩余时长， {{ scope.row.surplusLadderPrice }}元/{{ scope.row.surplusLadderTime }}分钟
                    <span v-if="scope.row.freeTime !== ''">,免费时长{{ scope.row.freeTime }}分钟</span>
                  </div>

                </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" prop="contactPerson">
                <template slot-scope="scope">
                  <el-link type="primary" style="color: #EA5504!important;" @click="deleteRule(scope.row)">删除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </easy-card>
        </div>
      </el-col>
    </el-row>
    <div class="save-button">
      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </div>
  </div>
</template>

<script>
import {getCalcDetailsByCalcTypeId, saveCalcRule} from "@/api/price";

export default {
  name: "add-price-rule",
  props: {
    id: String
  },
  data() {
    return {
      showTimeInput: false, //默认禁用时间选择
      form: {
        plateType: ["0"], //选择车牌  0通用  01黄牌  02蓝牌  03黑牌  04新能源
        activeTimeType: "0", //生效周期 0不限  1工作日  2休息日
        activeLegalHolidays: 1, //启用法定节假日  0启用  1不启用
        effectualTime: "0", //有效时段  0全天  1分时段
        activeTimeStart: "", //分时段  开始时间
        activeTimeEnd: "", //分时段  结束时间
        ruleType: "0", //计费方式 0:按时1:按次2:按阶梯
        price: "", //单位价格
        calcUnit: "", //单位时长
        notEnoughUnit: "0", //不足单位时长
        unitChange: "0", //切换计费时段
        // unitCollectPrice: "", //按次计费--单次收费价格
        freeTime: "", //按次计费--按次免费时长
        // ladderStartTime: "0", //按阶梯计费--开始分钟
        // ladderEndTime: "", //按阶梯计费--结束分钟
        // ladderPrice: "", //按阶梯计费--收费金额
        // ladderUnitTime: "", //按阶梯计费--单位时长
        surplusLadderPrice: "", //按阶梯计费--剩余阶梯金额
        surplusLadderTime: "", //按阶梯计费--剩余阶梯分钟
        calcDetailList: [], //阶梯列表
      },
      priceRuleTable: [], //已添加表格
      rules: {
        plateType: [{required: true, message: "请选择车牌"}],
        activeTimeStart: [
          {type: "date", required: true, message: "请选择开始时间"},
        ],
        activeTimeEnd: [{type: "date", required: true, message: "请选择结束时间"}],
        price: [
          {
            required: true,
            message: "请正确输入金额（小数点后最多两位的数字）",
            trigger: 'blur'
          },
        ],
        calcUnit: [
          {required: true, message: "请正确输入单位时长（不小于零的数字）", trigger: 'blur'},
        ],
      },

      options: [
        {
          value: "0",
          label: "通用",
          disabled: false,
        },
        {
          value: "01",
          label: "黄牌",
          disabled: true,
        },
        {
          value: "02",
          label: "蓝牌",
          disabled: true,
        },
        {
          value: "06",
          label: "黑牌",
          disabled: true,
        },
        {
          value: "03,04",
          label: "新能源",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    //处理车牌显示
    formatNumplate(items) {
      return items.map((item) => {
        const map = {
          "0": "通用",
          "01": "黄牌",
          "02": "蓝牌",
          "06": "黑牌",
          "03,04": "新能源",
        };
        return map[item];
      })
          .join(",");
    },
    //处理阶梯计费
    formatLadderList(items) {
      items.map((item) => {
        item.text = `${item.calcStart}~${item.calcEnd}分钟, ${item.price}元`
      })
    },
    //判断车牌为通用其他禁选
    changeValue() {
      const option = this.options;
      if (this.form.plateType[0] === "0") {
        option.map((item) => {
          if (item.value === "0") {
            item.disabled = false;
          } else {
            item.disabled = true;
          }
        });
      } else if (this.form.plateType.length === 0) {
        option.map((item) => {
          item.disabled = false;
        });
      } else {
        option.map((item) => {
          if (item.value === "0") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      }
    },
    //判断是否显示时间选择  0全天不显示  1分时段显示
    changeDisable() {
      if (this.form.effectualTime === "0") {
        this.showTimeInput = false;
      } else {
        this.showTimeInput = true;
      }
    },
    //阶梯列表生成
    foundLadderList() {
      const list = [];
      const i = list.length + 1;
      list.push({
        calcNo: i,
        calcStart: 0,
        calcEnd: "",
        price: "",
        calcUnit: "",
      });
      this.form.calcDetailList = list;
    },
    //添加一行阶梯计费
    addLadder() {
      const list = this.form.calcDetailList;
      if (list[list.length - 1].calcEnd === "") {
        this.$message.error("请填写结束时间");
        return;
      } else if (list[list.length - 1].price === "") {
        this.$message.error("请填写收费金额");
        return;
      }
      // else if (list[list.length - 1].calcUnit === "") {
      //   this.$message.error("请填写单位时长");
      //   return;
      // }
      const i = list.length + 1;
      const time = list[list.length - 1].calcEnd;
      list.push({
        calcNo: i,
        calcStart: time,
        calcEnd: "",
        price: "",
      });
      this.form.calcDetailList = list;
    },
    //删除一行阶梯计费
    deleteLadder() {
      var list = this.form.calcDetailList;
      if (list.length > 1) {
        list.pop();
        this.form.calcDetailList = list;
      }
    },
    //添加
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          const addList = JSON.parse(JSON.stringify(this.form));
          const list = this.form.calcDetailList;
          if (addList.ruleType === "0") {
            // addList.freeTime = "";
            addList.calcDetailList = [];
          }
          if (addList.ruleType === "1") {
            addList.calcDetailList = [];
            addList.calcUnit = "";
            addList.notEnoughUnit = "";
            addList.unitChange = "";
          }
          if (addList.ruleType === "2") {
            if (list[list.length - 1].calcEnd === "") {
              this.$message.error("请填写结束时间");
              return;
            } else if (list[list.length - 1].price === "") {
              this.$message.error("请填写收费金额");
              return;
            }
            // else if (list[list.length - 1].calcUnit === "") {
            //   this.$message.error("请填写单位时长");
            //   return;
            // }
            if (addList.surplusLadderPrice === "") {
              this.$message.error("请填写剩余阶梯金额");
              return;
            }
            if (addList.surplusLadderTime === "") {
              this.$message.error("请填写剩余阶梯分钟");
              return;
            }
            // addList.calcUnit = "";
            addList.notEnoughUnit = "";
            addList.unitChange = "";
            // addList.freeTime = "";
          }

          if (addList.effectualTime === "0") {
            addList.activeTimeStart = "00:00";
            addList.activeTimeEnd = "23:59"
          }

          console.log(addList);

          this.priceRuleTable.push(addList);

          this.form.calcDetailList = [];
          this.foundLadderList();
          this.form.activeLegalHolidays = 1;
          this.form.activeTimeStart = "";
          this.form.activeTimeEnd = "";
          this.form.surplusLadderPrice = "";
          this.form.surplusLadderTime = "";
          this.showTimeInput = false;
          // this.resetForm(form);
          this.form.plateType = ["0"];
          this.form.activeTimeType = "0";
          this.form.effectualTime = "0";
          this.form.ruleType = "0";
          this.form.price = "";
          // this.form.calcUnit = "";
          this.form.notEnoughUnit = "0";
          this.form.unitChange = "0";
          // this.form.freeTime = "";

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除已添加的
    deleteRule(e) {
      const ruleList = this.priceRuleTable;
      const id = e;
      ruleList.splice(ruleList.indexOf(id), 1);
    },
    //重置
    resetForm(form) {
      this.$refs[form].resetFields();
    },
    //保存表单
    save() {
      const saveList = this.priceRuleTable;
      const list = [];
      saveList.map(item => {
        var o = {
          ...item
        }
        o.plateType = o.plateType.join(",");

        if (o.ruleType === "2" &&
            o.calcDetailList[o.calcDetailList.length-1].calcEnd!=='99999') {
          o.calcDetailList.push({
            calcNo: o.calcDetailList.length + 1,
            calcStart: o.calcDetailList[o.calcDetailList.length - 1].calcEnd,
            calcEnd: "99999",
            price: o.surplusLadderPrice,
            calcUnit: o.surplusLadderTime,
          })
        }
        list.push(o);
      })
      const body = {
        objectId: this.$route.query.id,
        calcDtoVo: list
      };
      saveCalcRule(body).then(res => {
        if (res && res.code === 30 && res.result) {
          this.$message.success("保存成功");
        }
      })
    },

    //处理日期

    formatActiveTimeScope(str) {
      const arr = str.split('-');
      return arr.map(item => {
        return item.substr(0, item.lastIndexOf(':'))
      })
    },

    //请求详情接口
    async getPriceRuleList() {
      const res = await getCalcDetailsByCalcTypeId(this.$route.query.id);
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        if (returnObject.calcResultVo.length > 0) {
          this.priceRuleTable = returnObject.calcResultVo.map(item => {
            const o = {};
            o.ruleType = item.ruleType.toString();
            o.activeLegalHolidays = item.activeLegalHolidays;
            o.activeTimeStart = this.formatActiveTimeScope(item.activeTimeScope)[0];
            o.activeTimeEnd = this.formatActiveTimeScope(item.activeTimeScope)[1];
            o.activeTimeType = item.activeTimeType.toString();
            o.effectualTime = item.effectualTime.toString();
            o.freeTime = item.freeTime;
            o.calcUnit = item.calcUnit;
            o.calcDetailList = [];

            // o.effectualTime = item.effectualTime.toString();

            // o.plateType = item.plateType;
            o.plateType = item.plateList;
            // o.price = item.price.toString();

            // o.plateType = item.plateType.split

            if (item.ruleType === 0) {
              // o.freeTime = '';
              o.surplusLadderPrice = '';
              o.surplusLadderTime = '';
              o.unitChange = item.unitChange.toString();
              o.calcUnit = item.calcUnit.toString();
              o.notEnoughUnit = item.notEnoughUnit.toString();
              o.price = item.price.toString();
            } else if (item.ruleType === 1) {
              o.unitChange = '';
              o.price = item.price.toString();
            } else if (item.ruleType === 2 && item.calcDetailList.length>0) {
              // 阶梯收费
              // o.surplusLadderPrice = item.surplusLadderPrice;
              // o.surplusLadderTime = item.surplusLadderTime;
              // debugger
              o.surplusLadderPrice = item.calcDetailList[item.calcDetailList.length - 1].price;
              o.surplusLadderTime = item.calcDetailList[item.calcDetailList.length - 1].calcUnit;
              // o.list = item.calcDetailList.pop();
              o.calcDetailList = item.calcDetailList.map(item1 => {
                return {
                  "calcNo": item1.calcNo,
                  "calcStart": item1.calcStart,
                  "calcEnd": item1.calcEnd.toString(),
                  "price": item1.price.toString(),
                  "calcUnit": item.calcUnit.toString(),
                  text: `${item1.calcStart}~${item1.calcEnd}分钟，${item1.price}元/${item.calcUnit}分钟`
                };
              })
              o.calcDetailList.pop();
            }

            return o;
          });
        }
      }
    },
  },
  mounted() {
    this.foundLadderList();
    this.getPriceRuleList();
  },
};
</script>

<style lang="less" scoped>
.box {
  padding: 10px 10px;
  //border: 1px solid #ebeef5;
}

.box-left {
  margin-right: 10px;
}

.checkbox {
  margin-left: 4px;
}

.el-icon-warning {
  color: blue;
  margin-left: -24px;
}

// /deep/.el-tooltip__popper .is-light {
//   background: #fff;
//   border: none;
// }
.tableTitle {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.tableContent {
  display: flex;
  flex-direction: row;
  text-align: center;
  position: relative;
  margin: 20px 0 0;
}

.ladderName {
  position: absolute;
  left: -56px;
}

.input-margin {
  margin: 0 10px;
}

.table-btn {
  text-align: right;
}

.save-button {
  margin-top: 26px;
}
</style>
