<template>
  <div class="add-free-top-rule">
    <el-row>
      <el-col :span="12">
        <div>
          <easy-card class="box box-left" title="添加">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
                label-position="left"
            >
<!--              <el-form-item label="全天封顶方式" label-width="120px" prop="limitType">-->
<!--                <el-select v-model="form.limitType">-->
<!--                  <el-option label="按自然日" value="0"></el-option>-->
<!--                  <el-option label="按24小时" value="1"></el-option>-->
<!--                  <el-option label="按整笔订单" value="2"></el-option>-->
<!--                </el-select>-->
<!--                <div>-->
<!--                  <el-button size="mini" type="primary" @click="setTopType">设置</el-button>-->
<!--                  <el-button size="mini" @click="resetTopType">重置</el-button>-->
<!--                </div>-->
<!--              </el-form-item>-->

<!--              <el-form-item label="选择车牌" prop="plateType">-->
<!--                <el-select-->
<!--                    v-model="form.plateType"-->
<!--                    multiple-->
<!--                    @change="changeValue"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in options"-->
<!--                      :key="item.value"-->
<!--                      :disabled="item.disabled"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->

<!--              <el-form-item label="生效周期">-->
<!--                <el-select v-model="form.activePeriod">-->
<!--                  <el-option label="不限" value="0"></el-option>-->
<!--                </el-select>-->
<!--                <el-select-->
<!--                    v-model="form.activeType"-->
<!--                >-->
<!--                  <el-option label="全天" value="0"></el-option>-->
<!--                   <el-option label="分时段" value="1"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->

<!--              <el-form-item v-if="showTimeInput === true" required>-->
<!--                  <el-time-picker-->
<!--                    v-model="form.activeTimeBegin"-->
<!--                    value-format="HH:mm"-->
<!--                    format="HH:mm"-->
<!--                    placeholder="开始时间"-->
<!--                    prop="beginTime"-->
<!--                  >-->
<!--                  </el-time-picker>-->
<!--                  <span> ~ </span>-->
<!--                  <el-time-picker-->
<!--                    v-model="form.activeTimeEnd"-->
<!--                    value-format="HH:mm"-->
<!--                    format="HH:mm"-->
<!--                    placeholder="结束时间"-->
<!--                  >-->
<!--                  </el-time-picker>-->
<!--                </el-form-item>-->

              <el-form-item label="封顶金额(元)" prop="maxLimit">
                <el-input v-model="form.maxLimit" class="input-width" placeholder="请输入封顶金额">
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button size="small" type="primary" @click="submitForm('form')"
                >确定
                </el-button>
                <el-button size="small" @click="resetForm('form')">重置</el-button>
              </el-form-item>
            </el-form>
          </easy-card>
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <easy-card class="box" title="已添加">
            <p class="remarkTip">
              减免方式：{{ topTypeList.limitType === "0" ? "按自然日" : topTypeList.limitType === "1" ? "按24小时" : "按整笔订单" }}</p>
            <el-table
                :data="topRuleTable"
                border
                class="common-table"
                height="400px"
                size="medium"
                style="width: 100%"
            >
<!--              <el-table-column label="生效车牌" prop="plateType">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{ formatNumplate(scope.row.plateType) }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="生效周期" prop="activePeriod">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{ scope.row.activePeriod === "0" ? "不限" : "" }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="时间段" prop="activeType">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{ scope.row.activeType === "0" ? "全天" : "" }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column label="封顶金额(元)" prop="maxLimit">
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-link type="primary" style="color: #EA5504!important;" @click="deleteRule(scope.row)">删除</el-link>
                </template>
              </el-table-column>
            </el-table>
          </easy-card>
        </div>
      </el-col>
    </el-row>
    <div class="save-button">
      <el-button
          class="xxd-button_save"
          type="primary"
          @click="save"
      >保存
      </el-button
      >
    </div>
  </div>
</template>

<script>
import {getCalcDetailsByCalcTypeId, saveCalcMaxLimit} from "@/api/price";

export default {
  name: "add-price-top-rule",
  props: {
    id: String
  },
  data() {
    return {
      showTimeInput: false, //默认禁用时间选择
      form: {
        limitType: "0", //全天封顶方式  0按自然日  1按24小时  2按整笔订单
        plateType: ["0"], //选择车牌
        activePeriod: "0", //生效周期  0不限 (1工作日 2休息日)只有不限
        activeType: "0", //0全天 (1分时段)只有全天
        // activeTimeBegin: "", //分时段开始时间
        // activeTimeEnd: "", //分时段结束时间
        maxLimit: "", //封顶金额
      },
      rules: {
        plateType: [{required: true, message: "请选择车牌"}],
        maxLimit: [
          {
            required: true,
            message: "请正确输入金额（小数点后最多两位的数字）",
          },
        ],
      },
      //全天封顶方式列表
      topTypeList: {
        limitType: "0",
      },
      topRuleTable: [], //已添加表格
      options: [
        {
          value: "0",
          label: "通用",
          disabled: false,
        },
        {
          value: "01",
          label: "黄牌",
          disabled: true,
        },
        {
          value: "02",
          label: "蓝牌",
          disabled: true,
        },
        {
          value: "06",
          label: "黑牌",
          disabled: true,
        },
        {
          value: "03,04",
          label: "新能源",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    //处理车牌显示
    formatNumplate(items) {
      return items.map((item) => {
        const map = {
          "0": "通用",
          "01": "黄牌",
          "02": "蓝牌",
          "06": "黑牌",
          "03,04": "新能源",
        };
        return map[item];
      })
          .join(",");
    },
    //判断车牌为通用其他禁选
    changeValue() {
      const option = this.options;
      if (this.form.plateType[0] === "0") {
        option.map((item) => {
          if (item.value === "0") {
            item.disabled = false;
          } else {
            item.disabled = true;
          }
        });
      } else if (this.form.plateType.length === 0) {
        option.map((item) => {
          item.disabled = false;
        });
      } else {
        option.map((item) => {
          if (item.value === "0") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      }
    },
    //判断是否显示时间选择  0全天不显示  1分时段显示
    // changeDisable() {
    //   if (this.form.activeType === "0") {
    //     this.showTimeInput = false;
    //   } else {
    //     this.showTimeInput = true;
    //   }
    // },
    //设置全天封顶方式
    setTopType() {
      const type = {
        limitType: this.form.limitType,
      }
      this.topTypeList = type;
    },
    //重置全天封顶方式
    resetTopType() {
      this.form.limitType = "0";
    },
    //添加
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          const list = {
            limitType: this.topTypeList.limitType,
            plateType: this.form.plateType,
            activePeriod: this.form.activePeriod,
            activeType: this.form.activeType,
            activeTimeBegin: "00:00", //只有全天选项，默认写死
            activeTimeEnd: "23:59", //只有全天选项，默认写死
            maxLimit: this.form.maxLimit,
          }
          // if(list.activeType === "0") {
          //   list.activeTimeBegin = "00:00";
          //   list.activeTimeEnd = "23:59";
          //   }
          this.topRuleTable = [];
          this.topRuleTable.push(list);
          this.resetForm(form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除已添加的
    deleteRule(e) {
      const ruleList = this.topRuleTable;
      const id = e;
      ruleList.splice(ruleList.indexOf(id), 1);
    },
    //重置
    resetForm(form) {
      this.$refs[form].resetFields();
      this.form.activePeriod = "0";
      this.form.activeType = "0";
      // this.form.activeTimeBegin = "";
      // this.form.activeTimeEnd = "";
      // this.showTimeInput = false;
    },
    //保存表单
    save() {
      const saveList = this.topRuleTable;
      const list = [];
      saveList.map(item => {
        var o = {
          ...item
        }
        // o.plateType = o.plateType.join(",");
        o.plateType = ''
        list.push(o);
      })
      const body = {
        objectId: this.$route.query.id,
        calcMaxLimitList: list,
      }

      saveCalcMaxLimit(body).then(res => {
        if (res.code === 30 && res.result) {
          this.$message.success("保存成功")
        }
      })
    },
    //请求详情接口
    async getTopRuleList() {
      const res = await getCalcDetailsByCalcTypeId(this.$route.query.id);
      if (res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        if (returnObject.calcMaxlimitResultVo.length > 0) {
          // debugger
          // this.topTypeList = {
          //   limitType: returnObject.calcMaxlimitResultVo[0].limitType.toString(),
          // }
          console.log(this.topTypeList);
          this.topRuleTable = returnObject.calcMaxlimitResultVo.map(item => {
            const o = {};
            // o.plateType = item.plateList;
            // o.activePeriod = item.activePeriod.toString();
            // o.activeType = item.activeType.toString();
            o.maxLimit = item.maxLimit.toString();
            return o;
          })
        }
      }
    },
  },
  mounted() {
    this.getTopRuleList();
  }

}
</script>

<style lang="less" scoped>
.box {
  padding: 10px 10px;
  //border: 1px solid #ebeef5;
}

.box-left {
  margin-right: 10px;
}

.checkbox {
  margin-left: 4px;
}

.el-icon-warning {
  color: blue;
  margin-left: -24px;
}

// /deep/.el-tooltip__popper .is-light {
//   background: #fff;
//   border: none;
// }
.tableTitle {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.tableContent {
  display: flex;
  flex-direction: row;
  text-align: center;
  position: relative;
  margin: 20px 0 0;
}

.ladderName {
  position: absolute;
  left: -56px;
}

.input-margin {
  margin: 0 10px;
}

.table-btn {
  text-align: right;
}

/deep/ .el-input-group {
  width: 230px !important;
}

.remarkTip {
  font-size: 14px;
  margin-bottom: 14px;
}

.save-button {
  margin-top: 26px;
}
</style>
