<template>
  <div class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div class="width110 font-size16 border-radius4004"
           style="background: #0768FD;color: white;display: flex; align-items: center;justify-content: center;cursor: pointer;"
           @click="goBack">
        返回
      </div>
      <div class="font-size16 margin-left30"
           style="color: black;font-weight: bold;display: flex; align-items: center;">
        {{ op==0?'新建计费规则':'编辑计费规则'}}
      </div>
    </div>
    <div class="tabs-wrap">
      <el-tabs type="border-card">
        <el-tab-pane label="计费规则">
          <PriceRule :id="id"/>
        </el-tab-pane>
<!--        <el-tab-pane label="免费时长">-->
<!--          <FreeTime :id="id"/>-->
<!--        </el-tab-pane>-->
        <el-tab-pane label="封顶规则">
          <TopRule :id="id"/>
        </el-tab-pane>
        <el-tab-pane label="预支付时长">
          <PaymentTime :id="id"/>
        </el-tab-pane>
        <!-- <el-tab-pane label="特殊免费">
            <SpecialFree />
        </el-tab-pane>
        <el-tab-pane label="特殊封顶">
            <SpecialTop />
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PriceRule from "./components/price_rule.vue";
// import FreeTime from "./components/free_time.vue";
import TopRule from "./components/top_rule.vue";
import PaymentTime from "./components/payment_time.vue";
// import SpecialFree from "./components/special_free.vue";
// import SpecialTop from "./components/special_top.vue";
export default {
  name: "add_price",
  components: {
    PriceRule,
    // FreeTime,
    TopRule,
    PaymentTime,
    // SpecialFree,
    // SpecialTop
  },
  data() {
    return {
      id: "",
      op:0, // 0-新建 1-编辑 2-查看
    }
  },
  methods: {
    goBack() {
      var item =  {
        "fullPath": "/parking_manage/detail?parkingId=69a6bf23f5c04ee6af0465222f4f8d46",
        "hash": "",
        "meta": {
          "requiresAuth": true,
          "isAdmin": true,
          "icon": "el-icon-bell",
          "hidden": true,
          "title": "停车场详情"
        },
        "name": "parking_detail",
        "params": {},
        "path": "/parking_manage/detail",
        "query": {
          "parkingId": "69a6bf23f5c04ee6af0465222f4f8d46"
        }
      };
      this.$store.commit("app/removeRoute", item);
      this.$router.back();
    },
  },
  mounted() {
    const id = this.$route.query.id;
    this.op = this.$route.query.op;
    if (id) {
      this.id = id;
    }
  }
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
